html { font-size: calc(1em + 1vw) }

.app{
    width: 100%;
    text-align: center;
    font-family: 'Raleway';
}

.app-light{
    color: black;
}

.app-dark{
    color: #f5f5f5;
}

.outer-container{
    display: flex;
    flex-direction: column;
    height: 97vh;
    width: 100%;
    padding-top: 5vh;
    display: inline-block;
    text-align: center;
}

.inner-container{
    width: 75%;
    height: 100%;
    margin-top: 1vh;
    padding: 10px;
    text-align: left;
    display: inline-block;
}

.background-container-dark{
    background-image: linear-gradient(rgb(20, 20, 20) 5%,rgb(15, 15, 15),  black);
    z-index: -1;
    position: fixed;
    width: 100%;
    height: 100%;
}

.background-container-light{
    background-image: linear-gradient(rgb(219, 219, 219) 5%,rgb(235, 235, 235),  #F5F5F5);
    z-index: -1;
    position: fixed;
    width: 100%;
    height: 100%;
}

.header-container{
    text-align: center;
}

p{
    font-size: .6em;
    font-weight: 300;
}

h2{
    font-weight: 200;
    font-size: 1.5em;
    text-align: center;
    display: block;
    margin-top: 10vh;
}

@media only screen and (max-width: 600px) {
    h2
{
    margin-top: 2vh;
}

}
h3{

}

h4{

}

h5{
    font-size: .6em;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 5px;
}

ul{
    margin-top: 10px;
}