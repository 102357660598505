canvas{
    width: 100%;
    height: 100%;
    z-index: -1;
    display: block;
}

.particle{
    width: 100%;
    height: 100%;
}