.home{
    display: flex;
    flex-direction: column;
    height: 95vh;
    width: 100%;
    padding-top: 5vh;
    align-items: center;
}

.name-container{
    position: relative;
    top: 40%;
    transform: perspective(1px) translateY(-40%);
}

.name{
    text-align: center;
    font-size: 7vmax;
    width: 100%;
    font-weight: 100;
}

.name-light{
    color: black;
}

.name-dark{
    color: #f5f5f5;
}

.title{
    text-align: center;
    font-size: 1.75vmax;
    font-weight: 200;
    top: -15%;
    margin: 0;
}

.title-light{
    color: rgb(34, 34, 34);
    position: relative;
    right: 0px;
}

.title-dark{
    color: #f5f5f5;
    position: relative;
    right: 0px;
    opacity: .6;
}

.image-container{
    margin-top: 15vh;
}

.fa {
    padding: .5vmax;
    margin: 1.5vmax;
    font-size: 2vmax;
    width: 3vmax;
    text-align: center;
    text-decoration: none;
    display: inline;
  }
  
  .fa:hover {
    opacity: 0.7;
  }

  .fa-facebook {
    color: black;
  }
  
  .fa-twitter {
    color: black;
  }

  .fa-linkedin {
    color: black;
  }

  .fa-light{
      color: black;
  }

  .fa-dark{
      color: #f5f5f5;
  }

  

