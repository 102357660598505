html { font-size: calc(1em + 1vw) }

.out{
    width: 100%;
    text-align: left;
}
.slider-container{
    width: 100%;
    height: 20%;
    display: inline-block;
    margin-top: 2vh;
}

.slider-container-inner{
    column-count: 2;
    padding: 5px;
    text-align: left;
    margin: 25px;
    height: 100%;
}

@media only screen and (max-width: 600px) {
    .slider-container-inner{
        column-count: 1;
    }

    .slider-container{
        margin-top: 0vh;
    }
}

.two-columns{
    column-count: 2;
    width: 100%;
    display: inline-block;
}

.column{
    height: 50%;
    text-align: left;
}

.slick-slide img{
    width: 70%;
    display: inline-block;
}

button.slick-prev{
    left: -35px;
}

button.slick.next{
    right: -35px;
}

.fa-react {
    color: rgb(0, 169, 221);
    display: inline-block;
  }

.fa-aws {
    color: rgb(255, 145, 0);
    display: inline-block;
}

.fa-windows {
    color: rgb(0, 145, 202);
    display: inline-block;
}

.fa-java {
    color: rgb(31, 133, 192);
    display: inline-block;
}

.company-title{
    display: inline;
    margin: 5px;
    font-weight: 400;
    font-size: .75em;
}

.project-title{
    display: inline;
}

.fa-light{
    color: black;
    margin-right: 10px;
}

.fa-dark{
    color: #f5f5f5;
    margin-right: 10px;
}


h4{
    margin-bottom: 5px;
    display: inline;
}

.project-link{
    text-decoration: none;
}

.project-link.dark{
    color: #f5f5f5;
}

.project-link.light{
    color: black;
}

.project-link:hover{
    opacity: 0.7;
}

.portfolio-image{
    width: auto !important;
    max-width: 100% !important;
    height: auto !important;
}

button.slick-prev {
    left: -35px;
}

.slick-prev:before, .slick-next:before {
    font-size: 30px;
    line-height: 1;
    opacity: .75;
}

.slick-light:before{
    color: black !important;
}

.slick-dark:before{
    color: #f5f5f5 !important;
}

