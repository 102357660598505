html { font-size: calc(1em + 1vw) }

.about-content{
    margin-top: 5%;
    height: 100%;
    width: 100%;
}

.skills-container
{
    display: flex;
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .skills-container
{
    flex-wrap: wrap;
    width: 100%;
}

}
.skill-column
{
    width: 100%;
}

li{
    font-size: .6em;
    text-align: left;
}

.school-link{
    color: inherit;
    text-decoration: none;
    font-weight: 600;
}

.school-link:visited{
    color: inherit;
}

.school-link:hover{
    opacity: 0.7;
}

.skill-list{
    margin-bottom: 2px;
}

