html { font-size: calc(1em + 1vw) }

.contact{
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    padding-top: 5vh;
}

.contact-form{
    opacity: 1;
    font-family: 'Raleway';
    display: inline;
}

.icon-container{
    text-align: center;
}

.contact-form-field{
    background-color: rgba(0,0,0,0);
    border-color: rgba(45,45,45,0.75);
    border-style: solid;
    border-width: 2px;
    font-family: 'Raleway';
    box-shadow: none;
    font-size: .6em;
    margin: 10px;
    padding: 5px;

}

.contact-form-field-light{
    color: black;
}

.contact-form-field-dark{
    color: #f5f5f5;
}

.message-body{
    width: 100%;
    height: 25vh;
}

.email{
    width: 25%;
}

.submit-icon {
    display: inline;
    cursor: pointer;
}

.contact-icon{
    text-align: center;
    width: 5vmax !important;
}

.submit-container{
    text-align: center;
}

.status-message{
    margin-top: 2px;
}

.contact-icon-container{
    display: relative;
    text-align: center;
    margin-top: 30%
}