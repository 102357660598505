html { font-size: calc(1em + 1vw) }

.nav-bar{
    width: 100%;
    height: 3vh;
    position: fixed;
    text-align: center;
    display: table;
    z-index:1;
}

.nav-bar-light{
    background-color: rgb(219, 219, 219, 0);
}

.nav-bar-dark{
    background-color: rgb(26, 26, 26, 0);
}  

.nav-btn{
    margin-left: 10%;
    margin-right: 10%;
    cursor: pointer;  
}

.nav-btn-container{
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    font-size: .6em;
    width: 20%;
}

.nav-btn-light{
    color: black;
    opacity: .65;

}

.nav-btn-dark{
    color: #f5f5f5;
    opacity: .65;
}

.nav-btn-active-light{
    margin-left: 10%;
    margin-right: 10%;
    cursor: default;
    color: rgba(0,0,0);
    opacity: 1;
}

.nav-btn-active-dark{
    margin-left: 10%;
    margin-right: 10%;
    color: #f5f5f5;
    cursor: default;
    opacity: 1;
}

.nav-btn-active-light:before, .nav-btn-active-dark:before, .nav-btn-active-light:after, .nav-btn-active-dark:after {
    opacity: 1;
}

.nav-btn-active-light:before, .nav-btn-active-dark:before{
    content: '{ ';
    left: 100px;
    transform: translateX(100px);
}

.nav-btn-active-light:after, .nav-btn-active-dark:after{
    content: ' }';
    right: 100px;
    transform: translateX(-10px);
}

.nav-btn:before, .nav-btn:after {
    opacity: 0;
    transition: all .5s;
}

.nav-btn:before {
    content: '{ ';
    left: 0px;
    transform: translateX(10px);
}

.nav-btn:after {
    content: ' }';
    right: 0px;
    transform: translateX(-10px);
}

.nav-btn:hover:before{
    opacity: 1;
}

.nav-btn:hover:after{
    opacity: 1;
}

.fa-sun{
    color: #f5f5f5;
    padding: 1vmax;
    font-size: 1.25vmax;
    width: 1vmax;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
}

.fa-sun:hover{
    opacity: 0.7;
}

.fa-moon{
    color:black;
    padding: 1vmax;
    font-size: 1.25vmax;
    width: 1vmax;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
}

.fa-moon:hover{
    opacity: 0.7;
}

@media only screen and (max-width: 600px) {
    .nav-bar {
        font-size: 1.1em;
    }

    .fa-sun, .fa-moon{
        font-size: 1.1em;
    }
}
